/* eslint-disable quotes */
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { getFieldSession, saveFieldSession } from 'util/sessionStorage'
import * as dayjs from 'dayjs'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'
import Typography from 'components/Typography'
import SwitchSelector from 'components/SwitchSelector'
import NavigatorBar from 'components/NavigatorBar'
import Steps from 'components/Steps'
import MediaQuery from 'react-responsive'

import AnimIn from 'components/AnimIn'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

const getDaysToAdd = (donationOptions, donationFrequency) => {
    let date = dayjs()

    if (donationFrequency === 'Current date') {
        date = dayjs()
    }

    if (donationOptions === 'Monthly') {
        date = date.add(1, 'month')
    }

    if (donationOptions === 'Quarterly') {
        date = date.add(365 / 4, 'days')
    }

    if (donationOptions === 'Twice a year') {
        date = date.add(365 / 2, 'days')
    }

    if (donationOptions === 'Annually') {
        date = date.add(365, 'days')
    }

    if (donationFrequency === '1st of the month') {
        date = date.date(1)
    }

    if (donationFrequency === '15th day of the month') {
        date = date.date(15)
    }

    return date.format('MMM DD, YYYY')
}

function Step3 ({ formRef, theme, values, isLoading, initialValues, handleFieldChange }) {
    const { t } = useTranslation(transDomain)
    const handleValidation = React.useCallback(() => {
        return formRef.current.reportValidity()
    }, [formRef])

    React.useEffect(() => {
        const session = getFieldSession('annual-step-3')

        if (session) {
            handleFieldChange('donationType', session.donationType, () => {
                handleFieldChange('donationOptions', session.donationOptions, () => {
                    handleFieldChange('donationFrequency', session.donationFrequency)
                })
            })
        }
    }, [handleFieldChange])

    return (
        <>
            <MediaQuery minWidth={1023}>
                <NavigatorBar
                    step={2}
                    validateMethod={handleValidation}
                    formRef={formRef}
                    values={values}
                />
            </MediaQuery>
            <MediaQuery maxWidth={1022}>
                <Steps step={2} steps={5} />
            </MediaQuery>
            <AnimIn>
                <Typography>
                    <div className={styles.container}>
                        <div className={styles.donationBox}>
                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={1}>
                                        <h2 className={styles.title}>
                                            Select the frequency of your donation.
                                        </h2>
                                        {((values.donationType && values.donationType !== 'one-time') &&
                                        <span className={styles.note}>
                                            <strong>Note:</strong> The first payment will be charged today.
                                        </span>
                                        )}
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>

                            <Form.Row>
                                <Form.RowColumns columnsMobile={1} columnsTablet={1}>
                                    <Form.RowColumn size={1}>
                                        <SwitchSelector
                                            selected={values.donationType || initialValues.donationType}
                                            options={['recurring', 'one-time']}
                                            required
                                            handleInputChange={value => {
                                                handleFieldChange('donationType', value)
                                                saveFieldSession('annual-step-3', {
                                                    donationFrequency: values.donationFrequency,
                                                    donationOptions: values.donationOptions,
                                                    donationType: value
                                                })
                                            }}
                                            trans={t}
                                            labelFormat='donationType.option.%name%'
                                />
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>

                            <Form.Row>
                                <Form.RowColumns columnsMobile={1} columnsTablet={1}>
                                    {((values.donationType && values.donationType !== 'one-time')) && (
                                        <>
                                            <Form.RowColumn size={0.5}>
                                                <Form.Field label={'Frequency of gift:'}>
                                                    <Form.Dropdown
                                                        options={['Monthly', 'Quarterly', 'Twice a year', 'Annually']}
                                                        trans={t}
                                                        placeholder={'Donation option'}
                                                        onChange={value => {
                                                            handleFieldChange('donationOptions', value)
                                                            saveFieldSession('annual-step-3', {
                                                                donationFrequency: values.donationFrequency,
                                                                donationType: values.donationType,
                                                                donationOptions: value
                                                            })
                                                        }}
                                                        defaultValue={values.donationOptions || initialValues.donationOptions}
                                                />
                                                </Form.Field>
                                            </Form.RowColumn>

                                            <Form.RowColumn size={0.5}>
                                                <Form.Field label>
                                                    <Form.Dropdown
                                                        options={['Current date', '1st of the month', '15th day of the month']}
                                                        trans={t}
                                                        placeholder={'Donation frequency'}
                                                        onChange={value => {
                                                            handleFieldChange('donationFrequency', value)
                                                            saveFieldSession('annual-step-3', {
                                                                donationOptions: values.donationOptions,
                                                                donationType: values.donationType,
                                                                donationFrequency: value
                                                            })
                                                        }}
                                                        defaultValue={values.donationFrequency || initialValues.donationFrequency}
                                                />
                                                </Form.Field>
                                            </Form.RowColumn>
                                            <Form.RowColumn size={1}>
                                                {((values.donationFrequency && values.donationOptions)) && (
                                                    <div className={styles.paymentInformation}>
                                                        <span>The next payment will be placed on: <strong>{getDaysToAdd(values.donationOptions, values.donationFrequency)}</strong></span>
                                                    </div>
                                                )}
                                            </Form.RowColumn>
                                            <Form.RowColumn size={1}>
                                                {((values.donationType && values.donationType !== 'one-time') &&
                                                <span className={styles.noteMobile}>
                                                    <strong>Note:</strong> The first payment will be charged today.
                                                </span>
                                                )}
                                            </Form.RowColumn>
                                        </>
                                    )}
                                </Form.RowColumns>
                            </Form.Row>
                            <Form.Row>
                                <Form.RowColumns>
                                    <Form.RowColumn size={1}>
                                        <div className={styles.btnContainer}>
                                            <Form.Submit
                                                isLoading={isLoading}
                                                label={t('continue')}
                                                style={{
                                                    background: theme.background,
                                                    color: theme.color
                                                }}
                                            />
                                        </div>
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>
                        </div>
                    </div>
                </Typography>
            </AnimIn>
        </>
    )
}

Step3.defaultProps = {
    values: {
        donationAmount: null,
        matchingGiftCompany: 'None',
        matchingGiftCompanyName: null,
        donationDesignations: null
    }
}

export default withForm()(withTheme(supportedThemes)(Step3))
