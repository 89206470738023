// @flow
import React, { useState, useCallback, useEffect, memo } from 'react'
import { withRouter } from 'react-router-dom'
import { animateScroll } from 'react-scroll'
import { isEqual } from 'lodash'
import { Store } from 'Store'
import { updateLead } from 'Actions'
import { ToastContainer, toast } from 'react-toastify'
import { getFieldSession } from 'util/sessionStorage'

import cn from 'classnames'

import styles from './styles.module.scss'

import 'react-toastify/dist/ReactToastify.css'

const CUSTOM_ID = 'toast-id-1'

const NavigatorBar = ({ values, history, location, match, validateMethod }) => {
    const [activeStep, setStep] = useState(window.sessionStorage.getItem('active_step'))

    const toastId = React.useRef(null)
    const [loading, setLoading] = useState(false)
    const { state, dispatch } = React.useContext(Store)

    const [enable2, setEnable2] = useState(false)
    const [enable3, setEnable3] = useState(false)
    const [enable4, setEnable4] = useState(false)
    const [enable5, setEnable5] = useState(false)

    const dismiss = React.useCallback(() => toast.dismiss(toastId.current), [toastId])

    const handleClick = useCallback(
        step => {
            return async () => {
                if (loading) return null
                if (activeStep === step) return null
                if (activeStep > step) return history.push(`/${match.params.url}/step-${step + 1}${location.search}`)

                if (validateMethod()) {
                    setLoading(true)

                    if (step >= 3) {
                        setTimeout(() => {
                            toastId.current = toast.warn('loading...', {
                                toastId: CUSTOM_ID,
                                autoClose: 1500,
                                limit: 1,
                                closeOnClick: true,
                                delay: 0
                            })
                        }, 500)

                        const session2 = getFieldSession('annual-step-2') || {}
                        const session3 = getFieldSession('annual-step-3') || {}
                        const session4 = getFieldSession('annual-step-4') || {}

                        await updateLead(dispatch, state.code, {
                            fields: {
                                ...state.lead.fields,
                                ...values,
                                selectedOptions: session2 || { ...state.lead.fields.selectedOptions, ...values.selectedOptions },
                                ...session3,
                                ...session4
                            }
                        })

                        setTimeout(() => {
                            dismiss()
                        }, 550)
                    } else {
                        updateLead(dispatch, state.code, {
                            fields: {
                                ...state.lead.fields,
                                ...values
                            }
                        })
                    }

                    setLoading(false)

                    return history.push(`/${match.params.url}/step-${step + 1}${location.search}`)
                }
            }
        },
        [activeStep, dismiss, dispatch, history, loading, location.search, match.params.url, state.code, state.lead.fields, validateMethod, values]
    )

    useEffect(() => {
        animateScroll.scrollToTop({
            duration: 250,
            delay: 0,
            smooth: 'easeOutQuad'
        })

        const step = match.url && match.url.split('/')[2] ? match.url.split('/')[2].split('-')[1] || 1 : 1
        window.sessionStorage.setItem('active_step', step - 1)
        setStep(step - 1)
        setTimeout(() => {
            dismiss()
        }, 0)
    }, [dismiss, match])

    const enableStep2 = React.useCallback(() => {
        let enable = true
        // values coming from local HOC state
        if (values.selectedOptions) {
            const keys = Object.keys(values.selectedOptions)
            if (keys.length > 0) {
                keys.forEach(key => {
                    if (values.selectedOptions[key].amount === 0) {
                        enable = false
                    }
                })
            } else {
                enable = false
            }
            // initialProps state using leads data
        } else if (!state.lead || !state.lead.fields || !state.lead.fields.selectedOptions || Object.keys(state.lead.fields.selectedOptions).length === 0) {
            enable = false
        }

        const session2 = getFieldSession('annual-step-2')
        if (session2 && Object.keys(session2).length >= 0) {
            enable = true
        }

        return enable
    }, [ state, values ])

    const enableStep3 = React.useCallback(() => {
        let enable = true
        const session3 = getFieldSession('annual-step-3')
        if (values.donationType) {
            if (values.donationType !== 'one-time') {
                if (!values.donationOptions || !values.donationFrequency) {
                    enable = false
                }
            } else {
                enable = true
            }
        } else if (state.lead || state.lead.fields) {
            if (!state.lead.fields.donationType) {
                enable = false
            } else if (state.lead.fields.donationType !== 'one-time') {
                if (!state.lead.fields.donationOptions || !state.lead.fields.donationFrequency) {
                    enable = false
                }
            }
        }

        if (session3) {
            if (session3.donationType === 'one-time') {
                enable = true
            } else if (session3.donationType !== 'one-time') {
                if (!session3.donationOptions || !session3.donationFrequency) {
                    enable = false
                }
            }
        }

        return enable
    }, [state, values])

    const enableStep4 = React.useCallback(() => {
        const session4 = getFieldSession('annual-step-4')

        let enable = true

        const {
            firstName,
            lastName,
            addressLine1,
            city,
            province,
            postalCode,
            mobilePhone,
            email,
            relationship
        } = values

        if (values) {
            if (
                !firstName ||
                !lastName ||
                !addressLine1 ||
                !city ||
                !province ||
                !postalCode ||
                !mobilePhone ||
                !email ||
                !relationship
            ) {
                enable = false
            }
        } else if (state.lead || state.lead.fields) {
            const {
                firstName,
                lastName,
                addressLine1,
                city,
                province,
                postalCode,
                mobilePhone,
                email,
                relationship
            } = state.lead.fields

            if (
                !firstName ||
                !lastName ||
                !addressLine1 ||
                !city ||
                !province ||
                !postalCode ||
                !mobilePhone ||
                !email ||
                !relationship
            ) {
                enable = false
            }
        }

        if (session4) {
            const {
                firstName,
                lastName,
                addressLine1,
                city,
                province,
                postalCode,
                mobilePhone,
                email,
                relationship
            } = session4

            if (
                firstName &&
                lastName &&
                addressLine1 &&
                city &&
                province &&
                postalCode &&
                mobilePhone &&
                email &&
                relationship
            ) {
                enable = true
            }
        }

        return enable
    }, [ state, values ])

    const enableStep5 = React.useCallback(() => {
        let enable = true
        return enable
    }, [])

    React.useEffect(() => {
        setEnable2(enableStep2())
        setEnable3(enableStep3())
        setEnable4(enableStep4())
        setEnable5(enableStep5())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, state, match.params.url])

    return (
        <>
            <ToastContainer
                className={styles.toast}
                position="bottom-left"
                closeButton={false}
                draggable={false}
                rtl={false}
            />

            <div className={styles.navigatorBarContainer}>
                <div className={styles.navigatorBar}>
                    <div>
                        <div className={cn(styles.navigatorBarOption, {
                            [styles.active]: activeStep === 0
                        })} onClick={handleClick(0)}>
                            Donation amount
                        </div>
                        <span className={styles.separator} />
                    </div>
                    <div>
                        <div className={cn(styles.navigatorBarOption, {
                            [styles.active]: activeStep === 1,
                            [styles.desabled]: !enable2
                        })} onClick={!enable2 ? null : handleClick(1)}>
                            Donation frequency
                        </div>
                        <span className={styles.separator} />
                    </div>
                    <div>
                        <div className={cn(styles.navigatorBarOption, {
                            [styles.active]: activeStep === 2,
                            [styles.desabled]: !enable2 || !enable3
                        })} onClick={!enable2 || !enable3 ? null : handleClick(2)}>
                            Donor information
                        </div>
                        <span className={styles.separator} />
                    </div>
                    <div>
                        <div className={cn(styles.navigatorBarOption, {
                            [styles.active]: activeStep === 3,
                            [styles.desabled]: !enable2 || !enable3 || !enable4
                        })} onClick={!enable2 || !enable3 || !enable4 ? null : handleClick(3)}>
                            Additional information
                        </div>
                        <span className={styles.separator} />
                    </div>

                    <div>
                        <div className={cn(styles.navigatorBarOption, {
                            [styles.active]: activeStep === 4,
                            [styles.desabled]: !enable2 || !enable3 || !enable4 || !enable5
                        })} onClick={!enable2 || !enable3 || !enable4 || !enable5 ? null : handleClick(4)}>
                            Customization
                        </div>
                    </div>

                    <span className={styles.activeLine} style={{
                        left: `${20 * activeStep}%`,
                        width: '20%'
                    }} />
                </div>
            </div>
        </>
    )
}

function areEqual (prevProps, nextProps) {
    return isEqual(prevProps, nextProps)
}

export default withRouter(memo(NavigatorBar, areEqual))
