import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import Typography from 'components/Typography'
import AnimIn from 'components/AnimIn'
import { getFieldSession, saveFieldSession } from 'util/sessionStorage'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'
import NavigatorBar from 'components/NavigatorBar'
import Steps from 'components/Steps'
import MediaQuery from 'react-responsive'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

function Step4 ({ formRef, theme, values, isLoading, handleFieldChange, initialValues }) {
    const { t } = useTranslation(transDomain)
    const handleValidation = React.useCallback(() => {
        return formRef.current.reportValidity()
    }, [formRef])

    React.useEffect(() => {
        const session = getFieldSession('annual-step-4')

        if (session) {
            const {
                firstName,
                lastName,
                addressLine1,
                city,
                province,
                postalCode,
                mobilePhone,
                email,
                relationship
            } = session

            handleFieldChange('firstName', firstName, () => {
                handleFieldChange('lastName', lastName, () => {
                    handleFieldChange('addressLine1', addressLine1, () => {
                        handleFieldChange('city', city, () => {
                            handleFieldChange('province', province, () => {
                                handleFieldChange('postalCode', postalCode, () => {
                                    handleFieldChange('mobilePhone', mobilePhone, () => {
                                        handleFieldChange('email', email, () => {
                                            handleFieldChange('relationship', relationship)
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        }
    }, [handleFieldChange])

    return (
        <>
            <MediaQuery minWidth={1023}>
                <NavigatorBar
                    step={2}
                    validateMethod={handleValidation}
                    formRef={formRef}
                    values={values}
                />
            </MediaQuery>
            <MediaQuery maxWidth={1022}>
                <Steps step={3} steps={5} />
            </MediaQuery>
            <AnimIn>
                <Typography>
                    <div className={styles.container}>
                        <div className={styles.donationBox}>
                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={1}>
                                        <h2 className={styles.title}>
                                            Please confirm your contact information.
                                        </h2>
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>

                            <Form.Row>
                                <Form.RowColumns columnsTablet={2} columnsMobile={1}>
                                    <Form.RowColumn size={0.5}>
                                        <Form.Field
                                            defaultValue={initialValues.firstName}
                                            value={values.firstName}
                                            onChange={value => {
                                                handleFieldChange('firstName', value)
                                                saveFieldSession('annual-step-4', {
                                                    lastName: values.lastName,
                                                    addressLine1: values.addressLine1,
                                                    city: values.city,
                                                    province: values.province,
                                                    postalCode: values.postalCode,
                                                    mobilePhone: values.mobilePhone,
                                                    email: values.email,
                                                    relationship: values.relationship,
                                                    firstName: value
                                                })
                                            }}
                                            type='text'
                                            required
                                            placeholder={t('placeholder.firstName')}
                                />
                                    </Form.RowColumn>

                                    <Form.RowColumn size={0.5}>
                                        <Form.Field
                                            defaultValue={initialValues.lastName}
                                            value={values.lastName}
                                            onChange={value => {
                                                handleFieldChange('lastName', value)
                                                saveFieldSession('annual-step-4', {
                                                    addressLine1: values.addressLine1,
                                                    city: values.city,
                                                    province: values.province,
                                                    postalCode: values.postalCode,
                                                    mobilePhone: values.mobilePhone,
                                                    email: values.email,
                                                    relationship: values.relationship,
                                                    firstName: values.firstName,
                                                    lastName: value
                                                })
                                            }}
                                            type='text'
                                            required
                                            placeholder={t('placeholder.lastName')}
                                />
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>

                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={1}>
                                        <Form.Field
                                            defaultValue={initialValues.addressLine1}
                                            value={values.addressLine1}
                                            onChange={value => {
                                                handleFieldChange('addressLine1', value)
                                                saveFieldSession('annual-step-4', {
                                                    lastName: values.lastName,
                                                    city: values.city,
                                                    province: values.province,
                                                    postalCode: values.postalCode,
                                                    mobilePhone: values.mobilePhone,
                                                    email: values.email,
                                                    relationship: values.relationship,
                                                    firstName: values.firstName,
                                                    addressLine1: value
                                                })
                                            }}
                                            type='text'
                                            required
                                            placeholder={t('placeholder.addressLine1')}
                            />
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>

                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={0.3}>
                                        <Form.Field
                                            defaultValue={initialValues.city}
                                            value={values.city}
                                            onChange={value => {
                                                handleFieldChange('city', value)
                                                saveFieldSession('annual-step-4', {
                                                    lastName: values.lastName,
                                                    addressLine1: values.addressLine1,
                                                    province: values.province,
                                                    postalCode: values.postalCode,
                                                    mobilePhone: values.mobilePhone,
                                                    email: values.email,
                                                    relationship: values.relationship,
                                                    firstName: values.firstName,
                                                    city: value
                                                })
                                            }}
                                            type='text'
                                            required
                                            placeholder={t('city')}
                                />
                                    </Form.RowColumn>

                                    <Form.RowColumn size={0.3}>
                                        <Form.Field
                                            defaultValue={initialValues.province}
                                            value={values.province}
                                            onChange={value => {
                                                handleFieldChange('province', value)
                                                saveFieldSession('annual-step-4', {
                                                    lastName: values.lastName,
                                                    addressLine1: values.addressLine1,
                                                    city: values.city,
                                                    postalCode: values.postalCode,
                                                    mobilePhone: values.mobilePhone,
                                                    email: values.email,
                                                    relationship: values.relationship,
                                                    firstName: values.firstName,
                                                    province: value
                                                })
                                            }}
                                            type='text'
                                            required
                                            placeholder={t('placeholder.province')}
                                />
                                    </Form.RowColumn>

                                    <Form.RowColumn size={0.3}>
                                        <Form.Field
                                            defaultValue={initialValues.postalCode}
                                            value={values.postalCode }
                                            onChange={value => {
                                                handleFieldChange('postalCode', value)
                                                saveFieldSession('annual-step-4', {
                                                    lastName: values.lastName,
                                                    addressLine1: values.addressLine1,
                                                    city: values.city,
                                                    province: values.province,
                                                    mobilePhone: values.mobilePhone,
                                                    email: values.email,
                                                    relationship: values.relationship,
                                                    firstName: values.firstName,
                                                    postalCode: value
                                                })
                                            }}
                                            type='number'
                                            required
                                            placeholder={t('placeholder.postalCode')}
                                />
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>

                            <Form.Row>
                                <Form.RowColumns columnsTablet={2} columnsMobile={1}>
                                    <Form.RowColumn size={1 / 2}>
                                        <Form.Field
                                            defaultValue={initialValues.mobilePhone}
                                            value={values.mobilePhone}
                                            onChange={value => {
                                                handleFieldChange('mobilePhone', value)
                                                saveFieldSession('annual-step-4', {
                                                    lastName: values.lastName,
                                                    addressLine1: values.addressLine1,
                                                    city: values.city,
                                                    province: values.province,
                                                    postalCode: values.postalCode,
                                                    email: values.email,
                                                    relationship: values.relationship,
                                                    firstName: values.firstName,
                                                    mobilePhone: value
                                                })
                                            }}
                                            type='text'
                                            required
                                            placeholder={t('placeholder.mobilePhone')}
                                />
                                    </Form.RowColumn>

                                    <Form.RowColumn size={1 / 2}>
                                        <Form.Field
                                            defaultValue={initialValues.email}
                                            value={values.email }
                                            onChange={value => {
                                                handleFieldChange('email', value)
                                                saveFieldSession('annual-step-4', {
                                                    lastName: values.lastName,
                                                    addressLine1: values.addressLine1,
                                                    city: values.city,
                                                    province: values.province,
                                                    postalCode: values.postalCode,
                                                    mobilePhone: values.mobilePhone,
                                                    relationship: values.relationship,
                                                    firstName: values.firstName,
                                                    email: value
                                                })
                                            }}
                                            type='email'
                                            required
                                            placeholder={t('placeholder.email')}
                                />
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>

                            <Form.Row>
                                <Form.RowColumns olumnsTablet={2} columnsMobile={1}>
                                    <Form.RowColumn size={1 / 2}>
                                        <span className={styles.information}>
                                            What is your relationship with Shift University?
                                        </span>
                                    </Form.RowColumn>

                                    <Form.RowColumn size={1 / 2}>
                                        <Form.Field>
                                            <Form.Dropdown
                                                options={['Alumni', 'Faculty / Staff', 'Friend', 'Current Student', 'Parent']}
                                                trans={t}
                                                placeholder={'Choose'}
                                                required
                                                onChange={value => {
                                                    handleFieldChange('relationship', value)
                                                    saveFieldSession('annual-step-4', {
                                                        lastName: values.lastName,
                                                        addressLine1: values.addressLine1,
                                                        city: values.city,
                                                        province: values.province,
                                                        postalCode: values.postalCode,
                                                        mobilePhone: values.mobilePhone,
                                                        email: values.email,
                                                        firstName: values.firstName,
                                                        relationship: value
                                                    })
                                                }}
                                                defaultValue={values.relationship || initialValues.relationship}
                                    />
                                        </Form.Field>
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>

                            <Form.Row>
                                <Form.RowColumns>
                                    <Form.RowColumn size={1}>
                                        <div className={styles.btnContainer}>
                                            <Form.Submit
                                                isLoading={isLoading}
                                                label={t('continue')}
                                                style={{
                                                    background: theme.background,
                                                    color: theme.color
                                                }}
                                />
                                        </div>
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>
                        </div>
                    </div>
                </Typography>
            </AnimIn>
        </>
    )
}

Step4.defaultProps = {
    values: {
        fullName: null,
        cardNumber: null,
        month: null,
        year: null,
        cardCode: null,
        zip: null
    }
}

export default withForm()(withTheme(supportedThemes)(Step4))
