import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import PrimaryButton from 'components/PrimaryButton'
import transDomain from './translations/index.translations'
import NavigatorBar from 'components/NavigatorBar'
import Steps from 'components/Steps'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import AnimIn from 'components/AnimIn'

import cn from 'classnames'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'
import Typography from 'components/Typography'

import styles from './styles.module.scss'

function Step5 ({ formRef, theme, values, isLoading, handleFieldChange, initialValues }) {
    const { t } = useTranslation(transDomain)
    const [honor, setShowHonor] = useState(initialValues.honor)
    const [matchDonations, setMatchDonations] = useState(initialValues.matchDonation)
    const [additionalInformation, setAdditionalInformation] = useState(initialValues.additionalInformation)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

    const handleShowHonor = useCallback(
        () => {
            if (!isTabletOrMobile) return null
            setShowHonor(!honor)
            handleFieldChange('honor', !honor)
        },
        [honor, isTabletOrMobile, handleFieldChange]
    )

    const handleHonorActive = useCallback(
        () => {
            setShowHonor(true)
            handleFieldChange('honor', true)
        },
        [handleFieldChange]
    )

    const handleHonorInactive = useCallback(
        () => {
            setShowHonor(false)
            handleFieldChange('honor', false)
        },
        [handleFieldChange]
    )

    const handleShowmatchDonations = useCallback(
        () => {
            if (!isTabletOrMobile) return null
            setMatchDonations(!matchDonations)
            handleFieldChange('matchDonation', !matchDonations)
        },
        [isTabletOrMobile, matchDonations, handleFieldChange]
    )

    const handleActivematchDonations = useCallback(
        () => {
            setMatchDonations(true)
            handleFieldChange('matchDonation', true)
        },
        [handleFieldChange]
    )

    const handleInactiveMatchDonations = useCallback(
        () => {
            setMatchDonations(false)
            handleFieldChange('matchDonation', false)
        },
        [handleFieldChange]
    )

    const handleAdditionalInformation = useCallback(
        () => {
            if (!isTabletOrMobile) return null
            setAdditionalInformation(!additionalInformation)
            handleFieldChange('additionalInformation', !additionalInformation)
        },
        [additionalInformation, isTabletOrMobile, handleFieldChange]
    )

    const handleActiveAdditionalInformation = useCallback(
        () => {
            setAdditionalInformation(true)
            handleFieldChange('additionalInformation', true)
        },
        [handleFieldChange]
    )

    const handleInactiveAdditionalInformation = useCallback(
        () => {
            setAdditionalInformation(false)
            handleFieldChange('additionalInformation', false)
        },
        [handleFieldChange]
    )

    const handleValidation = React.useCallback(() => {
        return formRef.current.reportValidity()
    }, [formRef])

    return (
        <>
            <MediaQuery minWidth={1023}>
                <NavigatorBar
                    step={4}
                    validateMethod={handleValidation}
                    formRef={formRef}
                    values={values}
                />
            </MediaQuery>
            <MediaQuery maxWidth={1022}>
                <Steps step={4} steps={5} />
            </MediaQuery>
            <AnimIn>
                <Typography>
                    <div className={styles.container}>
                        <MediaQuery maxWidth={1022}>
                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={1}>
                                        <h2 className={cn(styles.title, styles.heading)}>
                                            Customize your donation.
                                        </h2>
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>
                        </MediaQuery>

                        <div className={styles.donationBox}>
                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={0.5}>
                                        <h2 className={cn(styles.title, {
                                            [styles.active]: honor
                                        })} onClick={handleShowHonor}>
                                            <span style={{ maxWidth: '220px' }}>
                                                Would you like to honor someone special?
                                            </span>
                                            <MediaQuery maxWidth={1022}>
                                                <div
                                                    className={styles.dropwdownIcon}
                                                    onClick={handleShowHonor}
                                                />
                                            </MediaQuery>
                                        </h2>
                                    </Form.RowColumn>
                                    <MediaQuery minWidth={1023}>
                                        <Form.RowColumn size={0.5}>
                                            <div className={styles.toggleArea}>
                                                <PrimaryButton active={honor} onClick={handleHonorActive}>
                                                    Yes
                                                </PrimaryButton>
                                                <PrimaryButton active={!honor} onClick={handleHonorInactive}>
                                                    No
                                                </PrimaryButton>
                                            </div>
                                        </Form.RowColumn>
                                    </MediaQuery>
                                </Form.RowColumns>
                            </Form.Row>

                            {(honor && honor === true) && (
                                <>
                                    <br />
                                    <Form.Row>
                                        <Form.RowColumns
                                            columnsTablet={2}
                                            columnsMobile={1}>
                                            <Form.RowColumn size={0.5}>
                                                <Form.Field>
                                                    <Form.Dropdown
                                                        options={['Alumni', 'Faculty / Staff', 'Friend', 'Current Student', 'Parent']}
                                                        trans={t}
                                                        placeholder={'Select tribute type'}
                                                        onChange={value => {
                                                            handleFieldChange('tributeType', value)
                                                        }}
                                                        defaultValue={values.tributeType || initialValues.tributeType}
                                            />
                                                </Form.Field>
                                            </Form.RowColumn>

                                            <Form.RowColumn size={0.5}>
                                                <Form.Field
                                                    value={values.honoreeName}
                                                    defaultValue={initialValues.honoreeName}
                                                    onChange={value => handleFieldChange('honoreeName', value)}
                                                    type='text'
                                                    placeholder={'Honoree name'}
                                        />
                                            </Form.RowColumn>
                                        </Form.RowColumns>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                            <Form.RowColumn size={1}>
                                                <span className={styles.info}>
                                                    Please provide the information below if you would like us to notify someone of your tribute.
                                                </span>
                                            </Form.RowColumn>
                                        </Form.RowColumns>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                            <Form.RowColumn size={1}>
                                                <Form.Field
                                                    value={values.notificationName}
                                                    defaultValue={initialValues.notificationName}
                                                    onChange={value => handleFieldChange('notificationName', value)}
                                                    type='text'
                                                    placeholder={'Notification Name'}
                                        />
                                            </Form.RowColumn>
                                            <Form.RowColumn size={1}>
                                                <Form.Field
                                                    value={values.honoreAddress}
                                                    defaultValue={initialValues.honoreAddress}
                                                    onChange={value => handleFieldChange('honoreAddress', value)}
                                                    type='text'
                                                    placeholder={'Address'}
                                        />
                                            </Form.RowColumn>
                                        </Form.RowColumns>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                            <Form.RowColumn size={0.3}>
                                                <Form.Field
                                                    defaultValue={initialValues.honoreCity}
                                                    value={values.honoreCity}
                                                    onChange={value => handleFieldChange('honoreCity', value)}
                                                    type='text'
                                                    placeholder={'City'}
                                        />
                                            </Form.RowColumn>
                                            <Form.RowColumn size={0.3}>
                                                <Form.Field
                                                    value={values.honoreState}
                                                    defaultValue={initialValues.honoreState}
                                                    onChange={value => handleFieldChange('honoreState', value)}
                                                    type='text'
                                                    placeholder={'State'}
                                        />
                                            </Form.RowColumn>
                                            <Form.RowColumn size={0.3}>
                                                <Form.Field
                                                    value={values.honorePostalCode}
                                                    initialValues={values.honorePostalCode}
                                                    onChange={value => handleFieldChange('honorePostalCode', value)}
                                                    type='text'
                                                    placeholder={'Zip Code'}
                                        />
                                            </Form.RowColumn>
                                        </Form.RowColumns>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                            <Form.RowColumn size={1}>
                                                <Form.Field
                                                    value={values.honoreComments}
                                                    defaultValue={initialValues.honoreComments}
                                                    onChange={value => handleFieldChange('honoreComments', value)}
                                                    type='text'
                                                    placeholder={'Comments related to tribute'}
                                        />
                                            </Form.RowColumn>
                                        </Form.RowColumns>
                                    </Form.Row>
                                </>
                            )}
                        </div>

                        <div className={styles.donationBox}>
                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={0.5}>
                                        <h2 className={cn(styles.title, {
                                            [styles.active]: matchDonations
                                        })} onClick={handleShowmatchDonations}>
                                            <span style={{ maxWidth: '220px' }}>
                                                Does your employer match donations?
                                            </span>
                                            <MediaQuery maxWidth={1022}>
                                                <div
                                                    className={styles.dropwdownIcon}
                                                    onClick={handleShowmatchDonations}
                                        />
                                            </MediaQuery>
                                        </h2>
                                    </Form.RowColumn>
                                    <MediaQuery minWidth={1023}>
                                        <Form.RowColumn size={0.5}>
                                            <div className={styles.toggleArea}>
                                                <PrimaryButton
                                                    active={matchDonations}
                                                    onClick={handleActivematchDonations}
                                        >
                                                    Yes
                                                </PrimaryButton>
                                                <PrimaryButton
                                                    active={!matchDonations}
                                                    onClick={handleInactiveMatchDonations}
                                        >
                                                    No
                                                </PrimaryButton>
                                            </div>
                                        </Form.RowColumn>
                                    </MediaQuery>
                                </Form.RowColumns>

                                {(matchDonations && matchDonations === true) && (
                                    <>
                                        <br />
                                        <Form.Row>
                                            <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                                <Form.RowColumn size={1}>
                                                    <Form.Field
                                                        value={values.matchComments}
                                                        defaultValue={initialValues.matchComments}
                                                        onChange={value => handleFieldChange('matchComments', value)}
                                                        type='text'
                                                        placeholder={'Employer name'}
                                            />
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>
                                    </>
                                )}
                            </Form.Row>
                        </div>

                        <div className={styles.donationBox}>
                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={0.5}>
                                        <h2 onClick={handleAdditionalInformation} className={cn(styles.title, {
                                            [styles.active]: additionalInformation
                                        })}>
                                            <span style={{ maxWidth: '260px' }}>
                                                Is there any additional information to provide with your donation?
                                            </span>
                                            <MediaQuery maxWidth={1022}>
                                                <div
                                                    className={styles.dropwdownIcon}
                                                    onClick={handleAdditionalInformation}
                                        />
                                            </MediaQuery>
                                        </h2>
                                    </Form.RowColumn>
                                    <MediaQuery minWidth={1023}>
                                        <Form.RowColumn size={0.5}>
                                            <div className={styles.toggleArea}>
                                                <PrimaryButton
                                                    active={additionalInformation}
                                                    onClick={handleActiveAdditionalInformation}
                                        >
                                                    Yes
                                                </PrimaryButton>
                                                <PrimaryButton
                                                    active={!additionalInformation}
                                                    onClick={handleInactiveAdditionalInformation}
                                        >
                                                    No
                                                </PrimaryButton>
                                            </div>
                                        </Form.RowColumn>
                                    </MediaQuery>
                                </Form.RowColumns>

                                {(additionalInformation && additionalInformation === true) && (
                                    <>
                                        <br />
                                        <br />
                                        <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                            <Form.RowColumn size={1}>
                                                <Form.Field
                                                    defaultValue={initialValues.additionalInformationComments}
                                                    value={values.additionalInformationComments}
                                                    onChange={value => handleFieldChange('additionalInformationComments', value)}
                                                    type='text'
                                                    placeholder={'Comments'}
                                            />
                                            </Form.RowColumn>
                                        </Form.RowColumns>
                                    </>
                                )}
                            </Form.Row>
                        </div>
                        <div className={styles.submitContainer}>
                            <Form.Row>
                                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                                    <Form.RowColumn size={1}>
                                        <div className={styles.btnContainer}>
                                            <Form.Submit
                                                isLoading={isLoading}
                                                label={t('continue')}
                                                style={{
                                                    background: theme.background,
                                                    color: theme.color
                                                }}
                                />
                                        </div>
                                    </Form.RowColumn>
                                </Form.RowColumns>
                            </Form.Row>
                        </div>
                    </div>
                </Typography>
            </AnimIn>
        </>
    )
}

Step5.defaultProps = {
    values: {}
}

export default withForm()(withTheme(supportedThemes)(Step5))
