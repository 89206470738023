// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

type ImagePath = string;

type Props = {
    theme: {
        background: string,
        color: string,
        logo: ImagePath,
    }
}

const Footer = (props: Props) => {
    const { theme } = props
    const { state } = React.useContext(Store)
    const address = state.campaign.client.address
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <img className={styles.logo} src={theme.logo} alt='' />

                <div className={styles.disclaimerContainer}>
                    <p className={styles.disclaimer}>{[
                        ((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
                        address.city,
                        address.province,
                        address.postalCode
                    ].join(' ')}
                    </p>
                    {state.campaign.contactInfo.phoneNumber || state.campaign.contactInfo.email
                        ? <p className={styles.disclaimer}>
                            {state.campaign.contactInfo.phoneNumber
                                ? <span>phone: <a href={`tel:${state.campaign.contactInfo.phoneNumber}`}>{state.campaign.contactInfo.phoneNumber}</a></span>
                                : null}
                            <span className={styles.divider} />
                            {state.campaign.contactInfo.email
                                ? <span>email: <a href={`mailto:${state.campaign.contactInfo.email}`}>{state.campaign.contactInfo.email}</a></span>
                                : null}
                        </p>
                        : null}
                </div>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Footer))
