// @flow
import React from 'react'
import { updateLead } from 'Actions'
import { Redirect } from 'react-router-dom'
import { Store } from 'Store'
import queryStr from 'query-string'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import Step3 from 'components/LeadGenForm/Step3'

export default withTheme(supportedThemes)(({
    history,
    location,
    match,
    theme
}) => {
    const parsedQuery = queryStr.parse(location.search)
    const byPhone = parsedQuery['by-phone'] === 'true'
    const byEmail = parsedQuery['by-email'] === 'true'
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (submitted) {
        return <Redirect to={`step-3${location.search}`} push />
    }

    if (!state.lead) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }

    return (
        <Step3
            byPhone={byPhone}
            byEmail={byEmail}
            toEmail={() => {
                delete parsedQuery['by-phone']
                parsedQuery['by-email'] = true
                history.push(`/${match.params.url}/step-3?${queryStr.stringify(parsedQuery)}`)
            }}
            toPhone={() => {
                delete parsedQuery['by-email']
                parsedQuery['by-phone'] = true
                history.push(`/${match.params.url}/step-3?${queryStr.stringify(parsedQuery)}`)
            }}
            toStep1={() => {
                delete parsedQuery['by-phone']
                delete parsedQuery['by-email']
                history.push(`/${match.params.url}/step-1?${queryStr.stringify(parsedQuery)}`)
            }}
            onSubmit={async (data, done) => {
                try {
                    let lead = {
                        fields: data,
                        step: 2,
                        isComplete: false
                    }
                    await updateLead(dispatch, state.code, lead)
                    setSubmitted(true)
                } catch (err) {
                    alert('Oops something went wrong')
                    console.error(err)
                    done(err)
                }
            }}
            initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
        />
    )
})
