// @flow
import React from 'react'
import { withTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import { Store } from 'Store'

const TopBar = ({
    theme
}) => {
    const { state } = React.useContext(Store)
    const address = state.campaign.client.address

    return (
        <div className={styles.container} style={{
            backgroundColor: theme.primaryColor
        }}>
            <span>{[((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
                address.city,
                address.province,
                address.postalCode
            ].join(' ')} {state.campaign.contactInfo.phoneNumber ? `| phone: ${state.campaign.contactInfo.phoneNumber}` : null} {state.campaign.contactInfo.email ? `| email: ${state.campaign.contactInfo.email}` : null}</span>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(TopBar))
