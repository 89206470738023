import React, { useState, useCallback } from 'react'
import { Scrollbar } from 'react-scrollbars-custom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import MediaQuery from 'react-responsive'
import NavigatorBar from 'components/NavigatorBar'
import Steps from 'components/Steps'
import AnimIn from 'components/AnimIn'
import { getFieldSession, saveFieldSession } from 'util/sessionStorage'
import { Currency } from 'i18n/NumberFormatter'

import { Store } from 'Store'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'
import Typography from 'components/Typography'

function Step2 ({ values, formRef, theme, isLoading, handleFieldChange, initialValues }) {
    const [ selectedOptions, setSelectedOptions ] = useState(initialValues.selectedOptions ? initialValues.selectedOptions : {})
    const [ dropwdownActive, setDropwdownActive ] = useState(true)
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const donationDesignations = state.campaign.website.donationDesignations
    const currencyFormatter = Currency(state.currency, state.locale)

    const handleDropdown = useCallback(
        () => {
            setDropwdownActive(!dropwdownActive)
        },
        [dropwdownActive]
    )

    React.useEffect(() => {
        const session = getFieldSession('annual-step-2')
        if (session) {
            setSelectedOptions(session)
        }
    }, [])

    const handleSelect = useCallback(
        key => {
            const nextSelectedState = { ...selectedOptions }

            if (nextSelectedState[key]) {
                nextSelectedState[key] = false
                delete nextSelectedState[key]
            } else {
                nextSelectedState[key] = {
                    label: key,
                    amount: 0
                }
            }

            setSelectedOptions(nextSelectedState)
            handleFieldChange('selectedOptions', nextSelectedState)
            saveFieldSession('annual-step-2', nextSelectedState)
        },
        [selectedOptions, handleFieldChange]
    )

    const totalAmount = React.useMemo(() => {
        let total = 0
        for (const key in selectedOptions) {
            if (Object.hasOwnProperty.call(selectedOptions, key)) {
                const { amount } = selectedOptions[key]
                total += amount
            }
        }
        return total
    }, [ selectedOptions ])

    const onChangeValue = useCallback(
        (key, value) => {
            const nextSelectedState = { ...selectedOptions }
            if (nextSelectedState[key]) {
                nextSelectedState[key] = {
                    label: key,
                    amount: value
                }
            }

            setSelectedOptions(nextSelectedState)
            handleFieldChange('selectedOptions', nextSelectedState)
            saveFieldSession('annual-step-2', nextSelectedState)
        },
        [selectedOptions, handleFieldChange]
    )

    const handleValidation = React.useCallback(() => {
        return formRef.current.reportValidity()
    }, [formRef])

    return (
        <>
            <MediaQuery minWidth={1023}>
                <NavigatorBar
                    step={5}
                    validateMethod={handleValidation}
                    formRef={formRef}
                    values={values}
                />
            </MediaQuery>
            <MediaQuery maxWidth={1022}>
                <Steps step={1} steps={5} />
            </MediaQuery>
            <AnimIn>
                <Typography>
                    <div className={styles.container}>
                        <Form.Row>
                            <Form.RowColumns columnsTablet={1}>
                                <Form.RowColumn size={0.5}>
                                    <div className={styles.donationBox}>
                                        <h2 className={cn(styles.title, {
                                            [styles.activeTitle]: dropwdownActive
                                        })} onClick={handleDropdown} >
                                            Select the areas that you would like to support.
                                            <MediaQuery maxWidth={1022}>
                                                <div className={styles.dropwdownIcon} />
                                            </MediaQuery>
                                        </h2>
                                        {
                                            <div className={cn(styles.dropwdown, {
                                                [styles.active]: dropwdownActive
                                            })}>
                                                <Scrollbar
                                                    className={styles.scrollbarsCustom}
                                                    style={{
                                                        width: '100%',
                                                        height: '350px'
                                                    }}
                                                    trackYProps={{
                                                        renderer: props => {
                                                            const { elementRef, style, ...restProps } = props
                                                            return (
                                                                <div
                                                                    {...restProps}
                                                                    style={{
                                                                        ...style,
                                                                        borderRadius: 0,
                                                                        backgroundColor: '#FFFFFF',
                                                                        display: 'block',
                                                                        width: 10,
                                                                        marginRight: undefined
                                                                    }}
                                                                    className={styles.trackY}
                                                                    ref={elementRef}
                                                        />
                                                            )
                                                        }
                                                    }}
                                                    thumbYProps={{
                                                        renderer: props => {
                                                            const { elementRef, style, ...restProps } = props
                                                            return (
                                                                <div
                                                                    {...restProps}
                                                                    style={{
                                                                        ...style,
                                                                        borderRadius: 0,
                                                                        backgroundColor: '#2D5AAF',
                                                                        display: 'block',
                                                                        width: '5px',
                                                                        marginLeft: '2.5px'
                                                                    }}
                                                                    ref={elementRef}
                                                            />
                                                            )
                                                        }
                                                    }}
                                                    >
                                                    <div className={styles.spacer} />
                                                    {donationDesignations.map((designation, i) => (
                                                        <div key={i}
                                                            className={cn(styles.checkmarkContainer, {
                                                                [styles.active]: selectedOptions[designation]
                                                            })}
                                                            onClick={() => handleSelect(designation)}
                                                >
                                                            <div className={styles.checkmark} />
                                                            <span>{designation}</span>
                                                        </div>
                                                    ))}
                                                    <div className={styles.spacer} />
                                                </Scrollbar>
                                            </div>
                                }
                                    </div>
                                </Form.RowColumn>
                                <Form.RowColumn size={0.5}>
                                    <div className={styles.selectionBox}>
                                        <h2 className={styles.title} style={{
                                            color: theme.primaryColor
                                        }}>
                                            How much would you like to donate to each selection?
                                        </h2>

                                        <div className={styles.currencyList}>
                                            {
                                        Object.keys(selectedOptions).map(key => {
                                            return (
                                                <div className={styles.currencyFieldContainer} key={key}>
                                                    <div className={styles.fakeLabel} style={{
                                                        color: theme.primaryColor
                                                    }}>
                                                        {selectedOptions[key].label}
                                                    </div>

                                                    <div className={styles.currencyContainer}>
                                                        <Form.CurrencyField
                                                            type='currency'
                                                            defaultValue={(selectedOptions[key].amount && selectedOptions[key].amount > 0) ? selectedOptions[key].amount : null}
                                                            value={(selectedOptions[key].amount && selectedOptions[key].amount > 0) ? selectedOptions[key].amount : null}
                                                            onChange={value => {
                                                                onChangeValue(key, value)
                                                            }}
                                                            currency={state.currency}
                                                            locale={state.locale}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                        </div>

                                        <div className={styles.currencyFieldContainer}>
                                            <div className={styles.fakeLabel} style={{
                                                color: theme.primaryColor
                                            }}>
                                                Donation amount
                                            </div>

                                            <div className={styles.currencyContainer}>
                                                <span className={styles.number}>
                                                    {currencyFormatter.format(totalAmount)}
                                                </span>
                                                <span className={styles.validationInput}>
                                                    <Form.CurrencyField
                                                        type='currency'
                                                        value={totalAmount === 0 ? null : totalAmount}
                                                        onChange={value => {}}
                                                        currency={state.currency}
                                                        locale={state.locale}
                                                        required
                                                />
                                                </span>
                                            </div>
                                        </div>
                                        <Form.Row>
                                            <Form.RowColumns>
                                                <Form.RowColumn size={1}>
                                                    <div className={styles.btnContainer}>
                                                        <Form.Submit
                                                            isLoading={isLoading}
                                                            label={t('continue')}
                                                            style={{
                                                                background: theme.background,
                                                                color: theme.color
                                                            }}
                                                        />
                                                    </div>
                                                </Form.RowColumn>
                                            </Form.RowColumns>
                                        </Form.Row>
                                    </div>
                                </Form.RowColumn>
                            </Form.RowColumns>
                        </Form.Row>
                    </div>
                </Typography>
            </AnimIn>
        </>
    )
}

Step2.defaultProps = {
    values: {}
}

export default withForm()(withTheme(supportedThemes)(Step2))
