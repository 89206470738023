import React from 'react'
import { Redirect } from 'react-router-dom'
import { Store } from 'Store'
import { updateLead } from 'Actions'
import Step4 from 'components/LeadGenForm/Step4'

import withTheme from 'hoc/withTheme'

import themes from './themes/__supportedThemes.js'
// import AuthorizeNetForm from 'components/Payments/AuthorizeNetForm'

export default withTheme(themes)(({ history, location, match, theme }) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (submitted) {
        return <Redirect to={`step-4${location.search}`} push />
    }

    if (!state.lead) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }

    return (
        <Step4
            onSubmit={async (data, done) => {
                try {
                    let lead = {
                        fields: data,
                        step: 3,
                        isComplete: false
                    }
                    await updateLead(dispatch, state.code, lead)
                    setSubmitted(true)
                } catch (err) {
                    alert('Oops something went wrong')
                    console.error(err)
                    done(err)
                }
            }}
            initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
        />
    )
})
