const KEY_PREFIX = 'CcJRWXKVCQ'

export const saveFieldSession = (key, fields = {}) => {
    try {
        window.sessionStorage.setItem(`${KEY_PREFIX}${key}`, JSON.stringify(fields))
    } catch (err) {
        alert(err)
    }
}

export const getFieldSession = key => {
    try {
        const fields = window.sessionStorage.getItem(`${KEY_PREFIX}${key}`)
        return fields ? JSON.parse(fields) : null
    } catch (err) {
        alert(err)
    }
}
