import React from 'react'
import { Redirect } from 'react-router-dom'
import { updateLead } from 'Actions'
import { Store } from 'Store'
import Step5 from 'components/LeadGenForm/Step5'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

export default withTheme(supportedThemes)(({ history, location, match, theme }) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    if (submitted) {
        return <Redirect to={`step-5${location.search}`} push />
    }

    if (!state.lead) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }

    return (
        <Step5
            onSubmit={async (data, done) => {
                try {
                    let lead = {
                        fields: data,
                        step: 4,
                        isComplete: false
                    }
                    await updateLead(dispatch, state.code, lead)
                    setSubmitted(true)
                } catch (err) {
                    alert('Oops something went wrong')
                    console.error(err)
                    done(err)
                }
            }}
            initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
        />
    )
})
