import React from 'react'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

const DonateOption = ({
    children,
    onClick,
    active,
    className,
    theme,
    styleProp
}) => {
    return (
        <div className={cn(styles.donateBtn, className, {
            [styles.active]: active
        })} onClick={onClick} style={{
            '--donate-active-option-color': theme.activeColor,
            '--inactive-text-color': theme.inactiveTextColor,
            '--active-text-color': theme.activeTextColor,
            '--border-color': theme.color,
            ...styleProp
        }}>
            {children && <span>{children}</span>}
        </div>
    )
}

DonateOption.defaultProps = {
    className: null
}

export default withTheme(supportedThemes)(DonateOption)
