import React from 'react'

import styles from './styles.module.scss'

export default function AnimIn ({ children }) {
    return (
        <div className={styles.animIn}>
            {children}
        </div>
    )
}
